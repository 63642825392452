import React from "react";
import { FunctionComponent } from "react";
import MainLayout from "../layouts/MainLayout/MainLayout";
import AgeCheckerSection from "../sections/AgeChecker/AgeCheckerSection";
import { ThemeProvider } from "styled-components";
import ThemeStyle from "../styles/ThemeStyle";

const age = () => {
  return (
    <ThemeProvider theme={ThemeStyle}>
      <AgeCheckerSection />
    </ThemeProvider>
  );
};

export default age;
